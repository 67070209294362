<template>
  <div>
    <b-modal
      id="modal-create-personalization-set"
      ref="modal-create-personalization-set"
      footer-class="flex-nowrap"
      size="lg"
      no-stacking
      no-close-on-backdrop
      centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.createPersonalizationSet.title') }}</h3>
      </template>

      <template #default>
        <div>
          <div
            v-if="showTypeSelection"
            class="mx-5 mb-4 personalization-type"
          >
            <h4 class="mt-4">{{ $t('modals.createPersonalizationSet.choseType') }}</h4>
            <div class="d-flex tile-wrapper">
              <div
                class="tile"
                :class="{active: activeTypeClass.isWebmagActive}"
                @click="selectType('webmag')"
              >
                <div class="mb-3">
                  <img src="@/assets/webmag-icon.png" alt="webmag logo">
                </div>
                <h2>{{ $t('modals.createPersonalizationSet.type.empty') }}</h2>
                <p>{{ $t('modals.createPersonalizationSet.type.emptyText') }}</p>
              </div>
              <div
                v-if="isSysAdmin"
                class="tile"
                :class="{
                  active: activeTypeClass.isMicrosoftActive && isSysAdmin,
                  disabled: !isSysAdmin
                }"
                @click="selectType('microsoft')"
              >
                <div class="mb-3">
                  <img src="@/assets/microsoft-dynamics.png" alt="microsoft dynamics logo">
                </div>
                <h2>{{ $t('modals.createPersonalizationSet.type.microsoft') }}</h2>
                <p>{{ $t('modals.createPersonalizationSet.type.microsoftText') }}</p>
              </div>
              <div class="tile disabled">
                <div class="mb-3">
                  <img src="@/assets/svg/mailchimp-icon.svg" alt="mailchimp logo">
                </div>
                <h2>{{ $t('modals.createPersonalizationSet.type.mailchimp') }}</h2>
                <p>{{ $t('modals.createPersonalizationSet.type.mailchimpText') }}</p>
              </div>
            </div>
          </div>
          <div v-else-if="type === 'webmag'">
            <div class="mt-4 mx-5 mb-3">
              <b-form-input
                v-model="personalizationSetName"
                :placeholder="$t('modals.createPersonalizationSet.name')"
                autofocus
                debounce="500"
                @keyup.enter="enterPressed"
              >
              </b-form-input>
            </div>
            <div class="mx-5 mb-4">
              <b-form-input
                v-model="personalizationSetDescription"
                :placeholder=" $t('modals.createPersonalizationSet.description') "
                debounce="500"
                @keyup.enter="enterPressed"
              >
              </b-form-input>
            </div>
          </div>
        </div>
      </template>

      <template #modal-footer>
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="cancelModal"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          :disabled="!enableOkButton"
          variant="primary"
          class="p-3"
          block
          @click="createPersonalizationSet"
        >
          {{ actionButtonText }}
        </b-button>
      </template>
    </b-modal>
    <personalization-microsoft-modal
      @create-personalization-set-microsoft-import="createMicrosoftImport"
      @close-modal-microsoft-import="cancelModal"
    />
  </div>
</template>

<script>
export default {
  name: 'ModalCreateNewPersonalizationSet',
  components: {
    PersonalizationMicrosoftModal: () => import('@/components/modals/personalization/microsoft/PersonalizationMicrosoftModal.vue'),
  },
  props: {
    isSysAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    personalizationSetName: null,
    personalizationSetDescription: null,
    type: null,
    activeTypeClass: {
      isWebmagActive: false,
      isMicrosoftActive: false,
    },
    webmagStep2: false,
    showTypeSelection: true,
    showMicrosoftModal: false,
  }),
  computed: {
    isPersonalizationSetNameSet() {
      return !!this.personalizationSetName;
    },
    enableOkButton() {
      if (this.type === 'microsoft' || (this.type === 'webmag' && !this.webmagStep2)) {
        return true;
      }
      if (this.type === 'webmag' && !this.isPersonalizationSetNameSet && this.webmagStep2) {
        return false;
      }
      if (this.type === 'webmag' && this.isPersonalizationSetNameSet && this.webmagStep2) {
        return true;
      }
      return false;
    },
    actionButtonText() {
      if ((this.type === 'webmag' && this.webmagStep2)) {
        return this.$t('modals.createPersonalizationSet.validate');
      }
      return this.$t('modals.createPersonalizationSet.next');
    },
  },
  mounted() {
    this.$refs['modal-create-personalization-set'].show();
  },
  methods: {
    createPersonalizationSet() {
      if (this.type === 'microsoft') {
        // this.$refs['modal-create-personalization-set'].hide();
        this.$root.$emit('bv::show::modal', 'modal-microsoft-import');
        this.showMicrosoftModal = true;
        this.showTypeSelection = false;
      }
      if (this.type === 'webmag') {
        this.webmagStep2 = true;
        this.showTypeSelection = false;
      }
      if (this.personalizationSetName && this.type === 'webmag') {
        this.$emit('create-personalization-set', {
          name: (this.personalizationSetName) ? this.personalizationSetName.trim() : null,
          description: (this.personalizationSetDescription)
            ? this.personalizationSetDescription.trim() : null,
        });
        this.$refs['modal-create-personalization-set'].hide();
      }
    },
    createMicrosoftImport(importObject) {
      this.$root.$emit('bv::hide::modal', 'modal-microsoft-import');
      this.$emit('create-personalization-set', {
        name: (importObject.setName) ? importObject.setName.trim() : null,
        description: (importObject.setDescription)
          ? importObject.setDescription.trim() : null,
        microsoftImport: importObject,
      });
    },
    cancelModal() {
      this.$emit('close-create-personalization-set-modal');
    },
    enterPressed() {
      if (this.personalizationSetName && this.type === 'webmag') {
        this.createPersonalizationSet();
      }
    },
    selectType(type) {
      this.type = type;
      switch (type) {
        case 'webmag': {
          // set all active classes to false
          Object.keys(this.activeTypeClass).forEach((prop) => {
            // key: the name of the object key
            // index: the ordinal position of the key within the object
            this.activeTypeClass[prop] = false;
          });
          this.activeTypeClass.isWebmagActive = true;
          break;
        }
        case 'microsoft': {
          if (this.isSysAdmin) {
            // set all active classes to false
            Object.keys(this.activeTypeClass).forEach((prop) => {
              // key: the name of the object key
              // index: the ordinal position of the key within the object
              this.activeTypeClass[prop] = false;
            });
            this.activeTypeClass.isMicrosoftActive = true;
          }
          break;
        }
        default: {
          break;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.tile-wrapper {
  flex-wrap: wrap;
  gap: 10px;
}
.tile {
  border: 1px solid #B9B9B9;
  width: calc(50% - 5px);
  min-height: 170px;
  padding: 15px;
  cursor: pointer;
  &.active {
    border: 1px solid rgba(83, 220, 134, 0.7);
    background-color: rgba(83, 220, 134, 0.1);
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  h2 {
    font-size: 15px;
    font-weight: bold;
    color: #9A9DA0;
  }
  p {
    margin: 0;
  }
}
</style>
